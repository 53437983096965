import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import ImageSlider from 'components/ImageSlider';
import ProductListDesktop from 'components/ProductListDesktop';
import ProductListMobile from 'components/ProductListMobile';

import { nl2br } from '../../utils/functions';
import { ProductListProps } from './models';

import './ProductList.scss';

const ProductList: FC<{ data: ProductListProps }> = ({
  data: {
    productList: {
      urls,
      seo,
      title,
      description,
      imageDesktop,
      imageMobile,
      imageDesktop1,
      imageMobile1,
      imageDesktop2,
      imageMobile2,
      imageDesktop3,
      imageMobile3,
      items,
      headband,
    },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  return (
    <Layout headerTransparent {...{ headband }}>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <div className="product-list">
        <div className="product-list__banner">
          <Image
            imageData={imageDesktop}
            alt={imageDesktop.altText}
            className="d-none d-md-block"
          />
          <Image imageData={imageMobile} alt={imageMobile.altText} className="d-block d-md-none" />
          <div className="product-list__bannerText">
            <div className="container-fluid">
              <div className="row">
                <div className="col text-center text-md-start">
                  <DangerouslySetInnerHtml html={nl2br(description)} element="span" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="product-list__slider">
          <ImageSlider
            title={title}
            imageDesktop1={imageDesktop1}
            imageDesktop2={imageDesktop2}
            imageDesktop3={imageDesktop3}
            imageMobile1={imageMobile1}
            imageMobile2={imageMobile2}
            imageMobile3={imageMobile3}
          />
        </div>
        <div className="product-list__products">
          <div className="pt-3 d-none d-md-block product-list__products--desktop">
            <ProductListDesktop items={items} />
          </div>
          <div className="py-3 d-block d-md-none product-list__products--mobile">
            <ProductListMobile items={items} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    productList(url: { eq: $url }) {
      urls {
        lang
        href
      }
      headband {
        cta {
          url
          name
        }
        imageDesktop {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
        imageMobile {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      seo {
        ...SEOStructureFragment
      }
      title
      description
      imageDesktop {
        altText
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
      }
      imageMobile {
        altText
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
      }
      imageDesktop1 {
        fallbackUrl
        altText
      }
      imageMobile1 {
        fallbackUrl
        altText
      }
      imageDesktop2 {
        fallbackUrl
        altText
      }
      imageMobile2 {
        fallbackUrl
        altText
      }
      imageDesktop3 {
        fallbackUrl
        altText
      }
      imageMobile3 {
        fallbackUrl
        altText
      }
      items {
        title
        description
        buy {
          name
          url
        }
        showMore {
          name
          url
        }
        imageDesktop {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
        imageMobile {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
        thumbnail {
          altText
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 700)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 700)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 700)
            }
          }
        }
        storeItem {
          image {
            altText
            fallbackUrl
          }
          link {
            name
            url
          }
        }
      }
    }
  }
`;

export default ProductList;
